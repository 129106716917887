/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

const Label = (props) => {
    return (
        <Typography variant="h2" color="textSecondary" sx={{
            fontSize: '1.75rem',
            marginTop: '0.0rem',
            marginBottom: '0.75rem'}}
        >
            {props.children}
        </Typography>
    );
};

// Choose the option that you would prefer out of the two given options.
// Option order shufflattu

// Apples vs. Oranges
// Cats vs. Dogs
// Coffee vs. Tea

// --> kun tarpeeksi vastauksia, kaikki on tilastollisesti merkittävää
// --> ota option order talteen


const GenericQuestion = (props) => {
    const [value, setValue] = useState('');
    const [options, setOptions] = useState([]);

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setValue(selectedValue);
        setTimeout(() => {
            props.updateQuestionValue(props.step.key, selectedValue + "-" + JSON.stringify(options));
        }, 750);
    };

    useEffect(() => {
        setValue('');
        const opts = props.step.options;
        opts.sort(() => Math.random() - 0.5);
        setOptions(opts);
    }, [props.step]);

    return (
        <>
            <FormControl component="fieldset">
                <Label>
                    <Trans i18nKey="pickTheOneYouWouldPrefer" />
                </Label>
                <RadioGroup
                    aria-label={props.step.key}
                    name={props.step.key}
                    value={value}
                    onChange={handleChange}
                >
                    {options.map((opt) => <FormControlLabel
                        value={opt}
                        control={<Radio />}
                        label={opt}
                    />)}
                </RadioGroup>
            </FormControl>
        </>
    );
};

const ExperimentInviteForm = (props) => {
    const [details, setDetails] = React.useState({
        participate: "",
      });

    const updateQuestionValue = (key, value) => {
        const data = { ...details, [key]: value };
        setDetails(data);
        props.postDetailsUpdate(props.detailsDataKey, data);

        if (key === "participate" && value === "no") {
            props.closeAction();
        }

        if (key === "participate" && value === "yes") {
            window.open('/studies/function-naming', '_blank');
            setTimeout(() => {
                props.closeAction();
            }, 5000);
        }
    };

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <Box p={2} mx="auto" m={2}>
                    <Typography mb={2}>
                        Tutkimme uudenlaista tehtävätyyppiä, jossa harjoitellaan funktioiden nimeämistä. Tutkimus kestää viidestä kymmeneen minuuttia ja siihen osallistutaan englanniksi.
                        <br/><br/> Ehtisitkö auttamaan meitä? Tutkimus avautuu uuteen ikkunaan.
                    </Typography>
                    <Button
                        color="inherit"
                        variant="outlined"
                        onClick={() => updateQuestionValue("participate", 'no')}
                        p={2}
                        m={2}
                        sx={{
                            marginRight: '2rem',}}
                        >
                        Ei kiitos
                    </Button>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => updateQuestionValue("participate", 'yes')}
                        ml={2}
                        pl={2}
                        >
                        Autan mielelläni
                    </Button>
                </Box>
            </div>
        </>
    );
};

export default ExperimentInviteForm;
