import { CssBaseline, Toolbar } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

// Localization: NOTE! THIS LOADS AND INITIATES
import "../locales/config.js";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

import ResearchAndCookieConsentDialog from "../components/consent/research-and-cookie-consent-dialog";
import ErrorNotification from "../components/error-notification";
import LoginNotification from "../components/login-notification";
import AiChat from "../components/ai-chat/ai-chat";
import NavBar from "../components/navbar/nav-bar";
import Notifier from "../components/notifier";
import Providers from "../providers/providers";
import favicon from "./img/101_dnd.png";
import UserActionMonitor from "../components/monitoring/user-action-monitor";
import SurveyDisplayer from "../components/survey/survey-displayer";
import SidebarPageLayout from "./page-layout";
import i18next from "i18next";
import localStorageUtil from "../util/local-storage-util";
import config from "../config";
import { getPreferredLanguage } from "../util/localisation-util";

const AppLayout: React.FC = ({ children, ...props }) => {
  const [wsdNotificationOpen, setWsdNotificationOpen] = React.useState(true);
  const [
    lookingForOldWsdNotificationOpen,
    setLookingForOldWsdNotificationOpen,
  ] = React.useState(true);
  const [fitechNotificationOpen, setFitechNotificationOpen] = React.useState(
    true,
  );
  const [dabNotificationOpen, setDabNotificationOpen] = React.useState(
    true,
  );


  const [
    lookingForDadNotificationOpen,
    setLookingForDadNotificationOpen,
  ] = React.useState(true);

  console.log("ROOT PROPS", props);
  const courseName = props.pageContext.courseName
    ? props.pageContext.courseName
    : "FITech 101: Digi & Data";

  let isDeprecatedWebSoftwareDevelopment = false;
  if (typeof window != "undefined" && window && window.location) {
    isDeprecatedWebSoftwareDevelopment = window?.location?.pathname?.includes(
      "/web-software-development",
    );
  }

  let lookingForNewDeviceAgnosticDesign = false;
  if (typeof window != "undefined" && window && window.location) {
    lookingForNewDeviceAgnosticDesign = window?.location?.pathname?.includes(
      "/device-agnostic-design",
    );
  }


  let isDeprecatingFitechCourses = false;
  if (typeof window != "undefined" && window && window.location) {
    isDeprecatingFitechCourses = window?.location?.pathname?.includes(
      "/fitech101",
    );
  }

  let isDeprecatingDab = false;
  if (typeof window != "undefined" && window && window.location) {
    isDeprecatingDab = window?.location?.pathname?.includes(
      "/designing-and-building-scalable-web-applications",
    );
  }

  const moduleCollectionName = props.pageContext.collection;
  const preferredLanguage = getPreferredLanguage();
  const [lang, setLang] = useState(preferredLanguage);

  const isModuleRoot = props.pageContext.pageType === "module-index-page";
  const isMaterialPage = props.pageContext.pageType === "material-content-page";
  const wrapWithSidebar = isModuleRoot || isMaterialPage;

  let hasAiSupport = false;

  // Set language based on module collection or user preference, default to English
  useEffect(() => {
    let lang = config.MODULE_COLLECTIONS[moduleCollectionName]?.lang;
    if (lang) {
      i18next.changeLanguage(lang);
      localStorageUtil.setItem("lang", lang);
    } else {
      lang = localStorageUtil.getStringItem("lang") ??
        (i18next.languages.includes(preferredLanguage)
          ? preferredLanguage
          : "en");
      lang && i18next.changeLanguage(lang);
    }
    setLang(lang);
  }, [moduleCollectionName]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{courseName}</title>
        <html lang={lang}></html>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta
          httpEquiv="Cache-Control"
          content="public, max-age=0, must-revalidate"
        />
        <link rel="icon" type="image/png" href={favicon} />
      </Helmet>
      <Providers {...props}>
        <UserActionMonitor>
          <CssBaseline />
          <Notifier />
          <NavBar {...props} />
          <ErrorNotification />
          <LoginNotification />
          <ResearchAndCookieConsentDialog />
          <SurveyDisplayer />
          <Toolbar />{" "}
          {/* <-- compensates for height changes across the materials */}
          {wrapWithSidebar
            ? <SidebarPageLayout {...props}>{children}</SidebarPageLayout>
            : children}
          {hasAiSupport ? <AiChat /> : null}
          {isDeprecatedWebSoftwareDevelopment
            ? (
              <Snackbar
                open={wsdNotificationOpen}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setWsdNotificationOpen(false)}
              >
                <Alert severity="warning">
                  These materials are no longer supported and completing this version of the course no longer yields official Aalto credits. These materials are available as a reference until the end of April 2025, after which they will no longer be available.<br/><br/>The supported Web Software Development course version is available {" "}
                  <a
                    href="/courses/web-software-development/"
                    target="_blank"
                  >
                    here
                  </a>.
                </Alert>
              </Snackbar>
            )
            : null}
          {isDeprecatingDab
            ? (
              <Snackbar
                open={dabNotificationOpen}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                onClose={() => setDabNotificationOpen(false)}
              >
                <Alert severity="warning">
                  These materials are no longer supported and completing this version of the course no longer yields official Aalto credits. These materials are available as a reference until the end of April 2025, after which they will no longer be available.<br/><br/>The supported Designing and Building Scalable Web Applications course version is available {" "} <a
                    href="/courses/designing-and-building-scalable-web-applications/"
                    target="_blank"
                  >
                    here
                  </a>.
                </Alert>
              </Snackbar>
            )
            : null}
          {isDeprecatingFitechCourses
            ? (
              <Snackbar
                open={fitechNotificationOpen}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                autoHideDuration={12000}
                onClose={() => setFitechNotificationOpen(false)}
              >
                <Alert severity="warning">
                  Uudelleenkirjoitamme FITech 101: Digi & Data -kurssisarjan
                  oppimateriaaleja. Nykyiset materiaalit ovat käytettävissä
                  huhtikuun 2025 loppuun. Julkaisemme kevään 2025 aikana päivitetyt versiot kurssimateriaaleista.
                </Alert>
              </Snackbar>
            )
            : null}
          {lookingForNewDeviceAgnosticDesign
          ? (
            <Snackbar
              open={lookingForDadNotificationOpen}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              autoHideDuration={30000}
              onClose={() => setLookingForDadNotificationOpen(false)}
            >
              <Alert severity="warning">
                These materials are no longer supported and completing this version of the course no longer yields official Aalto credits. These materials are available as a reference until the end of April 2025, after which they will no longer be available.<br/><br/>The supported Device-Agnostic Design course version is available {" "}
                  <a
                    href="/courses/device-agnostic-design/"
                    target="_blank"
                  >
                    here
                  </a>.
              </Alert>
            </Snackbar>
          )
          : null}
        </UserActionMonitor>
      </Providers>
    </React.Fragment>
  );
};

export default AppLayout;
